import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, threeGrid, threeGrid2, elem2, infos, info, image, orange, subTitle } from "../../../../src/pages/works/WorkPage.module.css";
import depthCam from "../../../../src/images/snapshot/depthCam.png";
import pointCloud from "../../../../src/images/snapshot/pointCloud.gif";
import depthInfo from "../../../../src/images/snapshot/depthInfo.gif";
import buckets from "../../../../src/images/snapshot/bucket.gif";
import allViews from "../../../../src/images/snapshot/demo_allview.gif";
import final from "../../../../src/images/snapshot/snapshot.gif";
import exhibition1 from "../../../../src/images/snapshot/display.jpg";
import exhibition2 from "../../../../src/images/snapshot/print.jpg";
import exhibition3 from "../../../../src/images/snapshot/morePrints.jpg";
import exhibition4 from "../../../../src/images/snapshot/printing.gif";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  threeGrid,
  threeGrid2,
  elem2,
  infos,
  info,
  image,
  orange,
  subTitle,
  depthCam,
  pointCloud,
  depthInfo,
  buckets,
  allViews,
  final,
  exhibition1,
  exhibition2,
  exhibition3,
  exhibition4,
  React
};