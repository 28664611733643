import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, twoGrid, threeGrid, threeGrid2, imgGrid, elem2, infos, info, image, image2, orange, subTitle, video2 } from "../../../../src/pages/works/WorkPage.module.css";
import gear1 from "../../../../src/images/rapid-proto/mechenism_a.jpg";
import gear2 from "../../../../src/images/rapid-proto/mechenism_b.jpg";
import gear_anim from "../../../../src/images/rapid-proto/mechenism.mp4";
import rhino1 from "../../../../src/images/rapid-proto/chair_a.jpg";
import rhino2 from "../../../../src/images/rapid-proto/chair_b.jpg";
import rhino3 from "../../../../src/images/rapid-proto/wavyArc_a.jpg";
import rhino5 from "../../../../src/images/rapid-proto/exterior_a.jpg";
import rhino6 from "../../../../src/images/rapid-proto/exterior_b.jpg";
import rhino7 from "../../../../src/images/rapid-proto/exterior_c.jpg";
import mb1 from "../../../../src/images/rapid-proto/moon_bunnies_a.jpg";
import mb2 from "../../../../src/images/rapid-proto/moon_bunnies_b.jpg";
import mb3 from "../../../../src/images/rapid-proto/moon_bunnies_c.jpg";
import mb4 from "../../../../src/images/rapid-proto/moon_bunnies_d.jpg";
import mb5 from "../../../../src/images/rapid-proto/moon_bunnies_e.jpg";
import mb6 from "../../../../src/images/rapid-proto/moon_bunnies_f.jpg";
import mb_anim from "../../../../src/images/rapid-proto/Animation.mp4";
import led_gg from "../../../../src/images/rapid-proto/gg.png";
import led_smile from "../../../../src/images/rapid-proto/smiley.png";
import led_arrows from "../../../../src/images/rapid-proto/arrows.gif";
import led_ok from "../../../../src/images/rapid-proto/ok.gif";
import led_snake from "../../../../src/images/rapid-proto/snake.gif";
import wood1 from "../../../../src/images/rapid-proto/wood1.png";
import wood2 from "../../../../src/images/rapid-proto/wood2.png";
import wood3 from "../../../../src/images/rapid-proto/wood3.png";
import wood4 from "../../../../src/images/rapid-proto/wood4.png";
import wire1 from "../../../../src/images/rapid-proto/wire1.png";
import wire2 from "../../../../src/images/rapid-proto/wire2.png";
import wire3 from "../../../../src/images/rapid-proto/wire3.png";
import wire4 from "../../../../src/images/rapid-proto/wire4.png";
import wires from "../../../../src/images/rapid-proto/wires.png";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  twoGrid,
  threeGrid,
  threeGrid2,
  imgGrid,
  elem2,
  infos,
  info,
  image,
  image2,
  orange,
  subTitle,
  video2,
  gear1,
  gear2,
  gear_anim,
  rhino1,
  rhino2,
  rhino3,
  rhino5,
  rhino6,
  rhino7,
  mb1,
  mb2,
  mb3,
  mb4,
  mb5,
  mb6,
  mb_anim,
  led_gg,
  led_smile,
  led_arrows,
  led_ok,
  led_snake,
  wood1,
  wood2,
  wood3,
  wood4,
  wire1,
  wire2,
  wire3,
  wire4,
  wires,
  React
};