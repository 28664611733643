import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, twoGrid, threeGrid, threeGrid2, elem2, infos, info, image, orange, subTitle } from "../../../../src/pages/works/WorkPage.module.css";
import morphsketch from "../../../../src/images/morph/sketch.png";
import p1 from "../../../../src/images/morph/progress1.mp4";
import p2 from "../../../../src/images/morph/progress2.mp4";
import p3 from "../../../../src/images/morph/progress3.mp4";
import p4 from "../../../../src/images/morph/progress4.mp4";
import p5 from "../../../../src/images/morph/prog5.mp4";
import p6 from "../../../../src/images/morph/prog6.mp4";
import movingposter from "../../../../src/images/morph/Morph_moving_poster.mp4";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  twoGrid,
  threeGrid,
  threeGrid2,
  elem2,
  infos,
  info,
  image,
  orange,
  subTitle,
  morphsketch,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  movingposter,
  React
};