import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, infos, info, orange, subTitle, twoGrid, threeGrid, threeGrid2, elem2 } from "../../../../src/pages/works/WorkPage.module.css";
import aero from "../../../../src/images/blinded/aero.mp4";
import aeroTest from "../../../../src/images/blinded/aero_test.mp4";
import ball from "../../../../src/images/blinded/ball.mp4";
import dress from "../../../../src/images/blinded/dress.mp4";
import koala from "../../../../src/images/blinded/turntable.mp4";
import initialStory from "../../../../src/images/blinded/storyboard.png";
import moodboard from "../../../../src/images/blinded/moodboard.png";
import light from "../../../../src/images/blinded/trees.gif";
import light2 from "../../../../src/images/blinded/house.gif";
import wind from "../../../../src/images/blinded/tree_Sway.mp4";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  infos,
  info,
  orange,
  subTitle,
  twoGrid,
  threeGrid,
  threeGrid2,
  elem2,
  aero,
  aeroTest,
  ball,
  dress,
  koala,
  initialStory,
  moodboard,
  light,
  light2,
  wind,
  React
};