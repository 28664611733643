import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, infos, info, orange, subTitle, twoGrid, threeGrid, threeGrid2, elem2 } from "../../../../src/pages/works/WorkPage.module.css";
import poster from "../../../../src/images/thalassia/BAWL.png";
import timeline from "../../../../src/images/thalassia/timeline.png";
import water from "../../../../src/images/thalassia/water.mp4";
import particle from "../../../../src/images/thalassia/interactive.mp4";
import combine from "../../../../src/images/thalassia/interaction1.mp4";
import combined from "../../../../src/images/thalassia/combined.mp4";
import test1 from "../../../../src/images/thalassia/testing.jpeg";
import test2 from "../../../../src/images/thalassia/testing3.jpeg";
import sketch2 from "../../../../src/images/thalassia/sketch2.jpg";
import sketch3 from "../../../../src/images/thalassia/sketch3.jpg";
import install from "../../../../src/images/thalassia/setup.jpeg";
import overview1 from "../../../../src/images/thalassia/overview.mp4";
import interaction from "../../../../src/images/thalassia/interactionfinal.mp4";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  infos,
  info,
  orange,
  subTitle,
  twoGrid,
  threeGrid,
  threeGrid2,
  elem2,
  poster,
  timeline,
  water,
  particle,
  combine,
  combined,
  test1,
  test2,
  sketch2,
  sketch3,
  install,
  overview1,
  interaction,
  React
};