import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, infos, info, orange, subTitle, twoGrid, threeGrid, threeGrid2, elem2 } from "../../../../src/pages/works/WorkPage.module.css";
import task from "../../../../src/images/spin/task.png";
import process from "../../../../src/images/spin/process1.png";
import mia from "../../../../src/images/spin/mia.png";
import kalen from "../../../../src/images/spin/kalen.png";
import affinity from "../../../../src/images/spin/affinity.png";
import current from "../../../../src/images/spin/currentmodel.png";
import future from "../../../../src/images/spin/futuremodel.png";
import lowfi from "../../../../src/images/spin/lowfi.png";
import styleGuide from "../../../../src/images/spin/style.png";
import mid1 from "../../../../src/images/spin/mid1.png";
import mid2 from "../../../../src/images/spin/mid2.png";
import midKalen from "../../../../src/images/spin/mid3a.png";
import midMia from "../../../../src/images/spin/mid3b.png";
import maps from "../../../../src/images/spin/maps.png";
import mid1Kalen from "../../../../src/images/spin/mid4a.png";
import mid1Mia from "../../../../src/images/spin/mid4b.png";
import mid2Kalen from "../../../../src/images/spin/mid5a.png";
import mid2Mia from "../../../../src/images/spin/mid5b.png";
import mid3 from "../../../../src/images/spin/mid6a.png";
import mid4 from "../../../../src/images/spin/mid6b.png";
import motion from "../../../../src/images/spin/motiondesign.png";
import motion2 from "../../../../src/images/spin/motion2.png";
import highfi from "../../../../src/images/spin/highfi.png";
import high1 from "../../../../src/images/spin/hifimap1.png";
import high2 from "../../../../src/images/spin/hifimap2.png";
import demo from "../../../../src/images/spin/hifimap2.png";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  infos,
  info,
  orange,
  subTitle,
  twoGrid,
  threeGrid,
  threeGrid2,
  elem2,
  task,
  process,
  mia,
  kalen,
  affinity,
  current,
  future,
  lowfi,
  styleGuide,
  mid1,
  mid2,
  midKalen,
  midMia,
  maps,
  mid1Kalen,
  mid1Mia,
  mid2Kalen,
  mid2Mia,
  mid3,
  mid4,
  motion,
  motion2,
  highfi,
  high1,
  high2,
  demo,
  React
};