exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cloth-js": () => import("./../../../src/pages/cloth.js" /* webpackChunkName: "component---src-pages-cloth-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-technicalanimation-js": () => import("./../../../src/pages/technicalanimation.js" /* webpackChunkName: "component---src-pages-technicalanimation-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-pages-works-mdx-slug-js": () => import("./../../../src/pages/works/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-works-mdx-slug-js" */),
  "component---works-blinded-mdx": () => import("./../../../works/blinded.mdx" /* webpackChunkName: "component---works-blinded-mdx" */),
  "component---works-create-mdx": () => import("./../../../works/create.mdx" /* webpackChunkName: "component---works-create-mdx" */),
  "component---works-fastpharm-mdx": () => import("./../../../works/fastpharm.mdx" /* webpackChunkName: "component---works-fastpharm-mdx" */),
  "component---works-morph-mdx": () => import("./../../../works/morph.mdx" /* webpackChunkName: "component---works-morph-mdx" */),
  "component---works-rapidproto-mdx": () => import("./../../../works/rapidproto.mdx" /* webpackChunkName: "component---works-rapidproto-mdx" */),
  "component---works-snapshot-mdx": () => import("./../../../works/snapshot.mdx" /* webpackChunkName: "component---works-snapshot-mdx" */),
  "component---works-spin-mdx": () => import("./../../../works/spin.mdx" /* webpackChunkName: "component---works-spin-mdx" */),
  "component---works-thalassia-mdx": () => import("./../../../works/thalassia.mdx" /* webpackChunkName: "component---works-thalassia-mdx" */)
}

