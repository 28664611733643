import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, threeGrid, threeGrid2, elem2, infos, info, image, orange, subTitle } from "../../../../src/pages/works/WorkPage.module.css";
import challenge from "../../../../src/images/create/challenge.png";
import hand from "../../../../src/images/create/handtrace.gif";
import butterfly from "../../../../src/images/create/butterfly.gif";
import createDemo from "../../../../src/images/create/demo.gif";
import dev from "../../../../src/images/create/devview.gif";
import pipeline from "../../../../src/images/create/pipeline.png";
import createinstall1 from "../../../../src/images/create/installation1.gif";
import createinstall2 from "../../../../src/images/create/installation2.gif";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  threeGrid,
  threeGrid2,
  elem2,
  infos,
  info,
  image,
  orange,
  subTitle,
  challenge,
  hand,
  butterfly,
  createDemo,
  dev,
  pipeline,
  createinstall1,
  createinstall2,
  React
};