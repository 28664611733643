// extracted by mini-css-extract-plugin
export var backToTop = "WorkPage-module--back-to-top--faff5";
export var buttons = "WorkPage-module--buttons--c7f87";
export var cardGrid = "WorkPage-module--card-grid--c0d17";
export var carousel = "WorkPage-module--carousel--7bd90";
export var closerLineHeight = "WorkPage-module--closerLineHeight--5715c";
export var content = "WorkPage-module--content--fae73";
export var descrip = "WorkPage-module--descrip--a62a3";
export var description = "WorkPage-module--description--d671a";
export var elem2 = "WorkPage-module--elem2--6b22a";
export var footDescrip = "WorkPage-module--footDescrip--378d5";
export var footer = "WorkPage-module--footer--48b32";
export var fullsize = "WorkPage-module--fullsize--c7b8d";
export var hero_img = "WorkPage-module--hero_img--295b2";
export var image = "WorkPage-module--image--bd9ac";
export var image2 = "WorkPage-module--image2--e2c07";
export var images = "WorkPage-module--images--8938b";
export var imgGrid = "WorkPage-module--img-grid--f5d50";
export var info = "WorkPage-module--info--f05e2";
export var infogrid = "WorkPage-module--infogrid--8bcfc";
export var infos = "WorkPage-module--infos--458c3";
export var nexttext = "WorkPage-module--nexttext--d69e1";
export var noFlow = "WorkPage-module--noFlow--d84c8";
export var noMargin = "WorkPage-module--noMargin--4d5cf";
export var noshow = "WorkPage-module--noshow--db22d";
export var orange = "WorkPage-module--orange--1d1dc";
export var other = "WorkPage-module--other--e34cc";
export var others = "WorkPage-module--others--5fd30";
export var overall = "WorkPage-module--overall--b2749";
export var overall2 = "WorkPage-module--overall2--2a6a8";
export var overlayTitle = "WorkPage-module--overlay-title--70c00";
export var overview = "WorkPage-module--overview--8aa10";
export var prevtext = "WorkPage-module--prevtext--55359";
export var projectWidth = "WorkPage-module--project-width--8d505";
export var projects = "WorkPage-module--projects--3abef";
export var relative = "WorkPage-module--relative--981ff";
export var smaller = "WorkPage-module--smaller--e624e";
export var subTitle = "WorkPage-module--subTitle--ac746";
export var tab = "WorkPage-module--tab--1e68b";
export var tabs = "WorkPage-module--tabs--bed15";
export var threeGrid = "WorkPage-module--three-grid--4aaae";
export var threeGrid2 = "WorkPage-module--three-grid2--075fc";
export var title = "WorkPage-module--title--138c2";
export var title2 = "WorkPage-module--title2--5f188";
export var title3 = "WorkPage-module--title3--5a201";
export var top = "WorkPage-module--top--40b0a";
export var toparrow = "WorkPage-module--toparrow--9be80";
export var topbutton = "WorkPage-module--topbutton--71d2b";
export var twoGrid = "WorkPage-module--two-grid--52a06";
export var video = "WorkPage-module--video--902da";
export var video2 = "WorkPage-module--video2--a6f73";
export var work = "WorkPage-module--work--1b901";
export var workCard = "WorkPage-module--work-card--48bc0";
export var workCardDescription = "WorkPage-module--work-card-description--90138";
export var workCardImg = "WorkPage-module--work-card-img--4e714";
export var workCardInner = "WorkPage-module--work-card-inner--51125";
export var workDescription = "WorkPage-module--work-description--a1ee7";
export var workImg = "WorkPage-module--work-img--a841b";
export var works = "WorkPage-module--works--bd8aa";