import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, infos, info, orange, subTitle, twoGrid, threeGrid, threeGrid2, video, elem2 } from "../../../../src/pages/works/WorkPage.module.css";
import plan from "../../../../src/images/fastpharm/plan.png";
import procon from "../../../../src/images/fastpharm/prosandcons2.png";
import procon2 from "../../../../src/images/fastpharm/prosandcons.png";
import cjm from "../../../../src/images/fastpharm/customerjourneymap.png";
import userflow from "../../../../src/images/fastpharm/userflow.png";
import paper from "../../../../src/images/fastpharm/lowfi.png";
import wireframe from "../../../../src/images/fastpharm/wireframe.png";
import midfi from "../../../../src/images/fastpharm/midfi.png";
import search from "../../../../src/images/fastPharm/symptomsearch.mp4";
import rec from "../../../../src/images/fastPharm/productrec.mp4";
import realtime from "../../../../src/images/fastPharm/realtimechat.mp4";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Link,
  infogrid,
  overview,
  closerLineHeight,
  infos,
  info,
  orange,
  subTitle,
  twoGrid,
  threeGrid,
  threeGrid2,
  video,
  elem2,
  plan,
  procon,
  procon2,
  cjm,
  userflow,
  paper,
  wireframe,
  midfi,
  search,
  rec,
  realtime,
  React
};